@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spinner {
  display: inline-block;
  width: 40px;
  height: 40px;
  border: 8px solid #f3f3f3;
  border-top-color: #ccc;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.spinner.small {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 4px solid #f3f3f3;
  border-top-color: #999;
  border-radius: 50%;
  animation: spin 2s linear infinite;
}

.text-base > p {
  margin-top: 8px;
}
