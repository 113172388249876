.headerButtonGroup {
  padding: 4px;
}

.headerButtonGroup > a {
  padding: 4px 8px;
}

.headerButtonGroup > a.selected {
  background-color: #f1f1f1;
  font-weight: bold;
  border-radius: 8px;
}
